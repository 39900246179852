import React from 'react'
import { graphql } from 'gatsby'
import { Hero, AboutUs } from 'src/sections'
import { Heading, Section, Wysiwyg } from 'src/components/Base'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import Seo from 'src/components/Seo'
import {
  cImageContainer,
  cTwoColumnsAbout,
} from 'src/templates/about-us-overview/styles.module.scss'

const AboutUsOverview = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const aboutUsOverview = page.atspAboutUsOverview
  const intro = aboutUsOverview.aboutUsIntro
  const yourSuccess = aboutUsOverview.aboutUsYourSuccess
  const aboutUsPartners = aboutUsOverview.aboutUsPartners
  const partnersImage = (
    <GatsbyImage
      className={cImageContainer}
      image={getImage(aboutUsPartners.image.localFile.childImageSharp)}
      alt={aboutUsPartners.image.altText}
    />
  )
  const partnersText = <Wysiwyg>{aboutUsPartners.text}</Wysiwyg>
  const aboutUsExperts = aboutUsOverview.aboutUsExperts
  const expertsImage = (
    <GatsbyImage
      className={cImageContainer}
      image={getImage(aboutUsExperts.image.localFile.childImageSharp)}
      alt={aboutUsExperts.image.altText}
    />
  )
  const expertsText = <Wysiwyg>{aboutUsExperts.text}</Wysiwyg>
  const aboutUsContact = page.atspContact.contactfiftyfifty
  const contactHeader = (
    <Heading as="h2" color="dark" size={2}>
      {aboutUsContact.heading}
    </Heading>
  )
  const contactText = <Wysiwyg>{aboutUsContact.text}</Wysiwyg>
  const contactButton = page.atspContact.contactButton

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Wysiwyg isCentered>{intro.text}</Wysiwyg>
      </Section>
      <AboutUs heading={yourSuccess.heading} text={yourSuccess.text} />
      <SectionTwoColumns
        heading={aboutUsPartners.heading}
        headingColor="dark"
        reorderMobile
        centerMobile
        className={cTwoColumnsAbout}
        contentLeft={partnersText}
        contentRight={partnersImage}
        buttonLeft
        buttonText={aboutUsPartners.buttonGroup.buttonText}
        buttonLink={aboutUsPartners.buttonGroup.buttonLink.url}
        hasBackground
      />
      <SectionTwoColumns
        heading={aboutUsExperts.heading}
        headingColor="dark"
        centerMobile
        className={cTwoColumnsAbout}
        contentLeft={expertsImage}
        contentRight={expertsText}
        buttonRight
        buttonText={aboutUsExperts.buttonGroup.buttonText}
        buttonLink={aboutUsExperts.buttonGroup.buttonLink.url}
      />
      <SectionTwoColumns
        hasBackground
        centerMobile
        contentLeft={contactHeader}
        contentRight={contactText}
        rightColumnRemoveDefaultMarginOnMobile
        buttonRight
        buttonText={contactButton.text}
        buttonLink={contactButton.link.link}
        hasDivider
      />
    </>
  )
}

export const pageQuery = graphql`
  query AboutUsOverview($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      content
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
      atspAboutUsOverview {
        aboutUsExperts {
          heading
          text
          buttonGroup {
            buttonText
            buttonLink {
              target
              title
              url
            }
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        aboutUsPartners {
          heading
          text
          buttonGroup {
            buttonText
            buttonLink {
              target
              title
              url
            }
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        aboutUsYourSuccess {
          heading
          text
        }
        aboutUsIntro {
          heading
          text
        }
      }
      atspContact {
        contactButton {
          text
          link {
            ... on WpPage {
              link
            }
          }
        }
        contactfiftyfifty {
          heading
          text
        }
      }
    }
  }
`
export default AboutUsOverview
